import { cva, VariantProps } from 'class-variance-authority';
import React from 'react';
import { CmdDivider } from '../divider';
import { cn } from '../util';

export type TypographyProps = {
  children?: React.ReactNode;
  text?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
} & VariantProps<typeof typographyStyles>;

export const typographyStyles = cva(['m-0'], {
  variants: {
    fontWeight: {
      medium: ['font-medium'],
      'semi-bold': ['font-semibold'],
      bold: ['font-bold'],
    },
    variant: {
      primary: ['text-gray1000'],
      secondary: ['text-contentBase'],
      tertiary: ['text-gray800'],
      contentMid: ['text-contentMid'],
    },
    fontSize: {
      sm: 'text-sm',
      base: 'text-base',
      md: 'text-md',
      lg: 'text-lg',
      xl: 'text-xl',
      xxl: 'text-xxl',
      xxxl: 'text-xxxl',
    },
  },
  defaultVariants: {
    fontWeight: 'medium',
    variant: 'primary',
    fontSize: 'base',
  },
});

const CmdTypography = ({ children, variant, fontWeight, fontSize, className, style }: TypographyProps) => {
  return (
    <Body variant={variant} fontWeight={fontWeight} fontSize={fontSize} className={cn(className)} style={style}>
      {children}
    </Body>
  );
};

const H1 = ({ children, variant, fontWeight, fontSize = 'xxl', className, style }: TypographyProps) => {
  return (
    <h1 className={cn(typographyStyles({ variant, fontWeight, fontSize }), className)} style={style}>
      {children}
    </h1>
  );
};

const H2 = ({ children, variant, fontWeight, fontSize = 'xl', className, style }: TypographyProps) => {
  return (
    <h2 className={cn(typographyStyles({ variant, fontWeight, fontSize }), className)} style={style}>
      {children}
    </h2>
  );
};

const H3 = ({ children, variant, fontWeight, fontSize = 'lg', className, style }: TypographyProps) => {
  return (
    <h3 className={cn(typographyStyles({ variant, fontWeight, fontSize }), className)} style={style}>
      {children}
    </h3>
  );
};

const SectionHeader = ({ children, text, variant, fontWeight, fontSize, className, style }: TypographyProps) => {
  return (
    <div className="w-full mt-lg mb-md">
      <div
        className={cn(
          'text-sm font-semibold opacity-50 uppercase mb-md',
          typographyStyles({ variant, fontWeight, fontSize }),
          className,
        )}
        style={style}
      >
        {children || text}
      </div>
      <CmdDivider />
    </div>
  );
};

const Body = ({ children, variant, fontWeight, fontSize = 'base', className, style }: TypographyProps) => {
  return (
    <p className={cn(typographyStyles({ variant, fontWeight, fontSize }), className)} style={style}>
      {children}
    </p>
  );
};

const HelpText = ({ children, variant, fontWeight, fontSize = 'sm', className, style }: TypographyProps) => {
  return (
    <p className={cn('text-sm', typographyStyles({ variant, fontWeight, fontSize }), className)} style={style}>
      {children}
    </p>
  );
};

CmdTypography.SectionHeader = SectionHeader;
CmdTypography.H3 = H3;
CmdTypography.H2 = H2;
CmdTypography.H1 = H1;
CmdTypography.Body = Body;
CmdTypography.HelpText = HelpText;

export { CmdTypography };
