import React from 'react';
import styled from '@emotion/styled';
import { IChecklist, IExperienceTemplate, INudgeType } from '@commandbar/internal/middleware/types';

import { Row, Typography, CB_COLORS, Modal } from '@commandbar/design-system/components/antd';

import { Template } from './templates';
import { CmdButton, CmdDivider, CmdDropdown, CmdLabel } from '@commandbar/design-system/cmd';
import { AlertTriangle, DotsVertical, Edit03, FlipBackward, Trash04 } from '@commandbar/design-system/icons/react';
import { useAppContext } from 'editor/src/AppStateContext';
import { useHistory } from 'react-router';
import { ReactComponent as AdminTemplateSVG } from '../../../img/admin_template.svg';
import { getTemplateDisplayTitle } from '@commandbar/internal/util/nudges';
import { getNudgeRoute } from '@commandbar/internal/proxy-editor/editor_routes';
import Z from '@commandbar/internal/client/Z';

interface WidgetTemplateProps<T extends INudgeType | IChecklist> {
  createFromTemplate: <U extends T | IExperienceTemplate['data']>(template?: U) => void;
  onBack: () => void;
  header: string;
  staticTemplates: Template<T>[];
  type: T extends IChecklist ? 'checklist' : INudgeType['type'];
}

const HoverStyle = styled.div`
  margin: 0px 8px 0 0;
  cursor: pointer;
  width: 150px;
  position: relative;

  .template-cards {
    height: 105px;
    border-radius: 4px;
    margin-bottom: 5px;
    transform: scale(1);
    transition: all 0.2s;
  }

  &:hover {
    .template-cards {
      transform: scale(1.02);
      transition: all 0.2s;
      opacity: 0.9;
    }
  }
`;

const DynamicTemplate = ({
  experienceTemplate,
  createFromTemplate,
}: {
  experienceTemplate: IExperienceTemplate;
  createFromTemplate: (template?: IExperienceTemplate['data']) => void;
}) => {
  const {
    organization,
    dispatch: {
      templates: { delete: deleteTemplate },
    },
  } = useAppContext();

  const history = useHistory();

  const [hovered, setHovered] = React.useState(false);
  const [dropDownOpen, setDropDownOpen] = React.useState(false);

  return (
    <HoverStyle
      key={experienceTemplate.id}
      onClick={() => createFromTemplate(experienceTemplate.data)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => {
        setHovered(false);
      }}
    >
      <div
        className="template-cards"
        style={{
          background:
            'radial-gradient(50% 50% at 50% 50%, rgba(160, 181, 249, 0.00) 42.5%, rgba(179, 196, 250, 0.92) 100%)',
        }}
      >
        {React.createElement(AdminTemplateSVG, { style: { height: '105px', width: '100%' } })}
      </div>
      <Typography.Paragraph>{getTemplateDisplayTitle(experienceTemplate)}</Typography.Paragraph>
      <div
        style={{ position: 'absolute', top: '8px', right: '8px', display: hovered || dropDownOpen ? 'block' : 'none' }}
      >
        <CmdDropdown.Menu onOpenChange={(open) => setDropDownOpen(open)}>
          <CmdDropdown.Trigger>
            <CmdButton variant="default" size={'default'} className="px-sm">
              <DotsVertical width={'16px'} height={'16px'} />
            </CmdButton>
          </CmdDropdown.Trigger>
          <CmdDropdown.Content>
            <CmdDropdown.Item
              onClick={async (e) => {
                e.stopPropagation();
                history.push(`${getNudgeRoute(experienceTemplate)}/template/${experienceTemplate.id}`);
              }}
            >
              <Edit03 /> Edit
            </CmdDropdown.Item>
            <CmdDivider />
            <CmdDropdown.Item
              onClick={async (e) => {
                e.stopPropagation();
                Modal.confirm({
                  zIndex: Z.Z_MODALS,
                  icon: <AlertTriangle height={22} width={22} className="anticon anticon-warning" />,
                  title: `Are you sure? This template will be removed and no longer be available to members of ${organization.name}.`,
                  async onOk() {
                    deleteTemplate(experienceTemplate.id);
                  },
                });
              }}
            >
              <Trash04 /> Delete
            </CmdDropdown.Item>
          </CmdDropdown.Content>
        </CmdDropdown.Menu>
      </div>
    </HoverStyle>
  );
};

const WidgetTemplate = <T extends INudgeType | IChecklist>({
  createFromTemplate,
  onBack,
  header,
  staticTemplates,
  type,
}: WidgetTemplateProps<T>) => {
  const { templates } = useAppContext();

  const adminTemplates = templates.filter((template) => template.type === type);

  return (
    <>
      <Row justify="space-between">
        <div style={{ display: 'flex' }}>
          <CmdButton onClick={onBack}>
            <FlipBackward />
          </CmdButton>
          <div style={{ padding: '0 5px', display: 'grid' }}>
            <Typography.Text
              style={{ fontWeight: 500, fontSize: '10px', lineHeight: '12px', color: CB_COLORS.neutral700 }}
              type="secondary"
            >
              {header}
            </Typography.Text>
            <Typography.Text
              style={{ fontWeight: 500, fontSize: '16px', lineHeight: '16px', color: CB_COLORS.neutral1000 }}
            >
              Templates
            </Typography.Text>
          </div>
        </div>

        <Typography.Paragraph
          style={{
            cursor: 'pointer',
            display: 'flex',
            marginBottom: 0,
            alignItems: 'center',
            fontSize: '12px',
            lineHeight: '16px',
            color: CB_COLORS.neutral1000,
            gap: '4px',
          }}
          onClick={() => createFromTemplate()}
        >
          Or start from scratch <span>&rarr;</span>
        </Typography.Paragraph>
      </Row>

      {adminTemplates.length > 0 && (
        <React.Fragment>
          <CmdLabel>Custom Templates</CmdLabel>
          <Row style={{ marginBottom: '8px' }}>
            {adminTemplates.map((experienceTemplate) => (
              <DynamicTemplate
                key={experienceTemplate.id}
                experienceTemplate={experienceTemplate}
                createFromTemplate={createFromTemplate}
              />
            ))}
          </Row>
        </React.Fragment>
      )}

      <CmdLabel>Built-in Templates</CmdLabel>
      <Row>
        {staticTemplates.map((staticTemplate) => {
          return (
            <HoverStyle key={staticTemplate.category} onClick={() => createFromTemplate(staticTemplate.template)}>
              <div className="template-cards">
                {React.createElement(staticTemplate.svg, { style: { height: '105px', width: '100%' } })}
              </div>
              <Typography.Paragraph>{staticTemplate.title}</Typography.Paragraph>
            </HoverStyle>
          );
        })}
      </Row>
    </>
  );
};

export default WidgetTemplate;
