import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import styled from '@emotion/styled';
import { ReactComponent as Drag } from '../../img/drag.svg';

import { CB_COLORS, Input } from '@commandbar/design-system/components/antd';

export { ReactComponent as CaretDown } from '../../img/caret_down.svg';
export { ReactComponent as CaretUp } from '../../img/caret_up.svg';
export { ReactComponent as Drag } from '../../img/drag.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const DragHandle = SortableHandle(() => (
  <div
    style={{
      width: 16,
      height: '100%',
      cursor: 'grab',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Drag style={{ width: 12, height: 12 }} />
  </div>
));

export const StyledTextArea = styled(Input.TextArea)`
  padding: 8px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid ${CB_COLORS.neutral500};
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #000000;
  resize: none;
  & .ant-input:placeholder-shown {
    position: relative;
    top: 1px;
    height: 30px;
    font-size: 14px !important;
    line-height: 14px;
    font-weight: 500;
    color: ${CB_COLORS.neutral500};
  }
`;
