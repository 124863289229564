import React from 'react';
import { motion } from 'framer-motion';
import { Spin } from 'antd';
import Z from '@commandbar/internal/client/Z';

type LoadingProps = {
  height?: number | string;
  width?: number | string;
};

// TODO: Move this to design-system and use it when we can import from there in the extension
export const LoadingLogoAnimation = ({ height = 68, width = 'auto' }: LoadingProps) => {
  return (
    <motion.svg viewBox="0 0 329 298" fill="none" xmlns="http://www.w3.org/2000/svg" height={height} width={width}>
      <motion.path
        animate={{
          pathLength: [0, 1, 0],
          transition: {
            pathLength: {
              duration: 2,
              repeat: Infinity,
              repeatType: 'reverse',
            },
          },
        }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M203.205 110.492L246.546 135.507L305.003 101.755L176.682 27.6773C169.066 23.2697 160.367 23.2697 152.75 27.6773L24.4295 101.755L82.8872 135.507L126.228 110.492L154.606 61.3137C156.771 57.6021 160.444 55.4756 164.736 55.4756C169.027 55.4756 172.739 57.6021 174.865 61.3137L203.244 110.492H203.205ZM126.189 187.508L82.8485 162.494L24.3908 196.246L152.712 270.323C160.328 274.731 169.027 274.731 176.644 270.323L304.965 196.246L246.507 162.494L203.166 187.508L174.788 236.687C172.623 240.398 168.95 242.525 164.658 242.525C160.367 242.525 156.655 240.398 154.529 236.687L126.151 187.508H126.189ZM164.697 90.5425L184.531 124.875C185.614 126.731 186.967 128.084 188.823 129.166L223.155 149L188.823 168.834C186.967 169.917 185.614 171.27 184.531 173.126L164.697 207.458L144.863 173.126C143.781 171.27 142.427 169.917 140.572 168.834L106.239 149L140.572 129.166C142.427 128.084 143.781 126.731 144.863 124.875L164.697 90.5425ZM269.936 149L316.679 175.987C324.103 180.278 328.394 187.663 328.394 196.246C328.394 204.829 324.141 212.214 316.679 216.505L188.668 290.389C173.435 299.204 155.959 299.204 140.726 290.389L12.7147 216.505C5.29153 212.214 1 204.829 1 196.246C1 187.663 5.25287 180.278 12.7147 175.987L59.4577 149L12.7147 122.014C5.29153 117.722 1 110.338 1 101.755C1 93.1716 5.25287 85.787 12.7147 81.4955L140.726 7.61141C155.959 -1.20364 173.435 -1.20364 188.668 7.61141L316.679 81.4955C324.103 85.787 328.394 93.1716 328.394 101.755C328.394 110.338 324.141 117.722 316.679 122.014L269.936 149Z"
        stroke="#242424"
        fill="none"
        strokeWidth="2"
      />
    </motion.svg>
  );
};

export const LoadingLogo = () => {
  return (
    <div
      style={{
        position: 'fixed',
        top: '0px',
        left: '0px',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        zIndex: Z.Z_INDEX_MAX,
      }}
    >
      <Spin indicator={<LoadingLogoAnimation />} />
    </div>
  );
};
