import React, { useEffect } from 'react';
import { Alert, AlertTitle, CmdCombobox, CmdLabel, CmdTextarea, CmdTooltip } from '@commandbar/design-system/cmd';
import { googleFonts } from '../../utils/google-fonts';
import { EditableThemeCustomFieldProps } from '../editableFieldTrees';
import { extractFontFamily, isFontAvailable } from '../../utils/font-utils';
import { InfoHexagon } from '@commandbar/design-system/icons/react';

const CUSTOM_STRING = 'Custom';
const FONT_ITEMS = [
  { label: CUSTOM_STRING, separator: true },
  {
    label: 'Google fonts',
    items: Object.keys(googleFonts)
      .slice(0, 250)
      .map((font) => {
        return { label: font };
      }),
  },
];

const FontWarningComponent = () => {
  return (
    <Alert variant="warning" className="rounded-md">
      <InfoHexagon />
      <AlertTitle>
        This font needs to be installed locally on your machine to preview it here.{' '}
        <a
          href="https://www.commandbar.com/docs/nudges/theming/theming-nudges/#how-do-fonts-work-in-themes"
          target="_blank"
          style={{ color: 'inherit', textDecoration: 'underline' }}
          rel="noreferrer"
        >
          Learn more.
        </a>
      </AlertTitle>
    </Alert>
  );
};

export const FontFamilyThemeField = ({ field, value, onChange }: EditableThemeCustomFieldProps) => {
  const [customValue, setCustomValue] = React.useState('');
  const [comboboxValue, setComboboxValue] = React.useState('');
  const [fontAvailable, setFontAvailable] = React.useState(true);

  // Determine whether this font is a G Font or not and set combobox value appropriately
  useEffect(() => {
    if (!value) {
      return;
    }
    const extractedFontFamily = extractFontFamily(value);
    const googleFont = googleFonts[extractedFontFamily];

    if (!comboboxValue) {
      // If combobox value is already set we want to just leave it alone
      if (`"${extractedFontFamily}"` === value) {
        setComboboxValue(extractedFontFamily);
      } else {
        setComboboxValue(CUSTOM_STRING);
      }
    }

    if (googleFont) {
      setFontAvailable(true); // We can assume known G fonts are always available
    } else if (extractedFontFamily) {
      setFontAvailable(isFontAvailable(extractedFontFamily)); // Check if font is available
    }
    setCustomValue(value);
  }, [value]);

  function onComboboxChange(value: string) {
    setComboboxValue(value);

    if (value !== CUSTOM_STRING) {
      const quotedValue = `"${value}"`; // wrap value in quotes before setting
      setCustomValue(quotedValue);
      onChange(quotedValue);
    }
  }

  function onCustomChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    setCustomValue(event.target.value);
    onChange(event.target.value);
  }

  return (
    <>
      <div className="flex justify-between">
        <div className="flex items-center">
          <CmdTooltip message={field.label}>
            <CmdLabel
              style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', userSelect: 'none' }}
            >
              {field.label}
            </CmdLabel>
          </CmdTooltip>
          <CmdLabel tooltip={field.tooltip} />
        </div>
        <CmdCombobox
          items={FONT_ITEMS}
          value={comboboxValue}
          emptyTriggerValue="Select a font"
          emptyState="No fonts found"
          onChange={onComboboxChange}
          triggerWidth="216px"
        />
      </div>
      {comboboxValue === CUSTOM_STRING && (
        <CmdTextarea
          fullWidth
          value={customValue}
          onChange={onCustomChange}
          placeholder='e.g. "mona sans", "hubot sans", inter'
        ></CmdTextarea>
      )}
      {!fontAvailable && <FontWarningComponent />}
    </>
  );
};
