import * as t from 'io-ts';
import { createObject, decodeThrowing, deleteObject, listObject, readObject, updateObject } from './generics';
import { AudienceV } from './helpers/audience';
import { LabeledActionV } from '@cb/types/entities/command/actions';

export const CopilotFallbackV = t.type(
  {
    id: t.number,
    title: t.string,
    message: t.string,
    is_default: t.boolean,
    trigger_event: t.union([t.literal('no_answer'), t.literal('negative_feedback')]),
    ctas: t.array(LabeledActionV),
    audience: t.union([t.null, AudienceV]),
    order_key: t.number,
  },
  'CopilotFallback',
);

export class CopilotFallback {
  public static decode = (data: any) => {
    return decodeThrowing(CopilotFallbackV, data);
  };
  public static create = createObject(CopilotFallbackV, CopilotFallbackV, 'copilot_fallbacks');
  public static update = updateObject(CopilotFallbackV, CopilotFallbackV, 'copilot_fallbacks');
  public static delete = deleteObject(CopilotFallbackV, 'copilot_fallbacks');
  public static list = listObject(CopilotFallbackV, 'copilot_fallbacks');
  public static read = readObject(CopilotFallbackV, 'copilot_fallbacks');
}
