import React from 'react';

import { InputContainer, Select, StyledLabel } from '@commandbar/design-system/components/antd';
import { isCompoundExpression, RuleExpression, type INamedRule } from '@commandbar/internal/middleware/helpers/rules';
import type { IAudienceType } from '@commandbar/internal/middleware/types';
import AudienceSize from './audiences/AudienceSize';
import { BorderedContainer, StyledSelect } from './components/styled';
import ConditionGroupEditor from './conditions/ConditionGroupEditor';
import { CONDITION_TYPE_CATEGORIES_FOR_TARGETING_WHO } from './conditions/categories';

import { ReactComponent as CaretDown } from '../img/caret_down.svg';
import { isConditionGroupValid } from './conditions/validate';

type AudienceSelectOption = { special: 'all' } | { special: 'custom' } | { rule_id: string };

interface AudienceSelectProps {
  audience: IAudienceType;
  rules: INamedRule[];
  onChange: (audience: IAudienceType) => void;
  disabled?: boolean;
  hasCustomAudience?: boolean;
  showOnlyServerSideAudiences?: boolean;
}

export const AudienceSelect = ({
  audience,
  rules,
  onChange,
  disabled = false,
  hasCustomAudience = true,
  showOnlyServerSideAudiences = false,
}: AudienceSelectProps) => {
  const audienceSelectValue = JSON.stringify(
    (() => {
      if (audience.type === 'all_users') return { special: 'all' };
      if (audience.type === 'rule_expression') return { special: 'custom' };
      return { rule_id: audience.rule_reference.rule_id };
    })(),
  );

  const audiences = (
    showOnlyServerSideAudiences
      ? [
          ...rules.filter((rule) => {
            if (!rule.is_audience) return false;

            const checkIfOnlyUserProperties = (expr: RuleExpression): boolean => {
              if (expr.type === 'CONDITION') {
                return expr.condition.type === 'user_property';
              } else if (isCompoundExpression(expr)) {
                return expr.exprs.every(checkIfOnlyUserProperties);
              }
              return false;
            };

            return checkIfOnlyUserProperties(rule.expression);
          }),
        ]
      : [...rules.filter((rule) => rule.is_audience)]
  ).sort((a, b) => a.name.localeCompare(b.name));

  return (
    <>
      <StyledSelect
        disabled={disabled}
        suffixIcon={<CaretDown />}
        showSearch
        optionLabelProp="label"
        filterOption={(input, option) =>
          typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
        }
        value={audienceSelectValue}
        onChange={(newValue) => {
          const option: AudienceSelectOption = JSON.parse(newValue);

          if ('special' in option) {
            if (option.special === 'all') {
              return onChange({ type: 'all_users' });
            }
            if (option.special === 'custom') {
              return onChange({ type: 'rule_expression', expression: { type: 'AND', exprs: [] } });
            }
          } else {
            onChange({
              type: 'named_rule_reference',
              rule_reference: {
                type: 'named_rule',
                rule_id: option.rule_id,
              },
            });
          }
        }}
        style={{ maxWidth: '100%', width: '100%' }}
        dropdownMatchSelectWidth={false}
      >
        <Select.Option label="All Users" value={JSON.stringify({ special: 'all' })}>
          All Users
        </Select.Option>
        {audiences.length > 0 && (
          <Select.OptGroup label={'Audiences'}>
            {audiences.map((rule) => (
              <Select.Option label={rule.name} key={rule.id} value={JSON.stringify({ rule_id: rule.id })}>
                {rule.name}
              </Select.Option>
            ))}
          </Select.OptGroup>
        )}

        {hasCustomAudience && (
          <Select.Option label={'Custom'} value={JSON.stringify({ special: 'custom' })}>
            Custom...
          </Select.Option>
        )}
      </StyledSelect>

      <InputContainer style={{ marginTop: 8 }}>
        <div style={{ width: '100%', margin: '12px 0' }}>
          <AudienceSize
            isInvalidRule={audience?.type === 'rule_expression' ? !isConditionGroupValid(audience.expression) : false}
            expr={
              audience.type === 'rule_expression'
                ? audience.expression
                : audience.type === 'named_rule_reference'
                ? audience.rule_reference
                : audience
            }
          />
        </div>

        {audience.type === 'rule_expression' && (
          <>
            <StyledLabel>Conditions</StyledLabel>

            <BorderedContainer>
              <ConditionGroupEditor
                disabled={disabled}
                onChange={(_expr) => {
                  onChange({ type: 'rule_expression', expression: _expr });
                }}
                expr={audience.expression}
                includeCategories={CONDITION_TYPE_CATEGORIES_FOR_TARGETING_WHO}
                excludeConditionTypes={['named_rule']}
              />
            </BorderedContainer>
          </>
        )}
      </InputContainer>
    </>
  );
};
