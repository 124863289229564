import React, { useEffect, useMemo } from 'react';
import { CmdDropdown } from '../dropdown';
import { CmdInput } from '../input';

export type CmdComboboxItem = {
  label: string;
  value?: string;
  items?: CmdComboboxItem[];
  separator?: boolean;
};

function renderItems(items: CmdComboboxItem[]) {
  return items.map((item) => {
    const value = item.value ?? item.label;
    const element: JSX.Element = item.items ? (
      <React.Fragment key={value}>
        <CmdDropdown.Label className="px-2 h-8 text-sm cursor-default">{item.label}</CmdDropdown.Label>
        {item.separator && <CmdDropdown.Separator />}
        {renderItems(item.items)}
      </React.Fragment>
    ) : (
      <React.Fragment key={value}>
        <CmdDropdown.RadioItem value={value} className="px-sm h-8">
          {item.label}
        </CmdDropdown.RadioItem>
        {item.separator && <CmdDropdown.Separator />}
      </React.Fragment>
    );
    return element;
  });
}

function filterItems(searchValue: string, items: CmdComboboxItem[]) {
  return items.reduce<CmdComboboxItem[]>((acc, item) => {
    if (item.items) {
      const filteredGroupItems = filterItems(searchValue, item.items);
      if (filteredGroupItems.length >= 1) {
        acc.push({ ...item, items: filteredGroupItems });
      }
    } else if (item.label.toLowerCase().includes(searchValue.toLowerCase())) {
      acc.push(item);
    }
    return acc;
  }, []);
}

function findItemByValue(value: string, items: CmdComboboxItem[]): CmdComboboxItem | undefined {
  for (const item of items) {
    if (value === item.value || value === item.label) {
      return item;
    } else if (item.items) {
      const recursiveSearch = findItemByValue(value, item.items);
      if (recursiveSearch) {
        return recursiveSearch;
      }
    }
  }
  return undefined;
}

interface Props {
  items: CmdComboboxItem[];
  value?: string;
  onChange: (value: string) => void;
  emptyTriggerValue?: string;
  emptyState?: string;
  searchable?: boolean;
  searchPlaceholder?: string;
  triggerWidth?: string;
  contentMaxHeight?: string;
}

const CmdCombobox = ({
  items,
  value,
  onChange,
  emptyTriggerValue = 'Select',
  emptyState,
  searchable = true,
  searchPlaceholder = 'Search',
  triggerWidth = '216px',
  contentMaxHeight = '360px',
}: Props) => {
  const [searchValue, setSearchValue] = React.useState('');
  const [selectedValue, setSelectedValue] = React.useState('');
  const [triggerValue, setTriggerValue] = React.useState('');

  const radioGroupRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (value) {
      setSelectedValue(value);
      setTriggerValue(findItemByValue(value, items)?.label ?? emptyTriggerValue);
    }
  }, [value]);

  const filteredMatches = useMemo(() => {
    return searchValue ? filterItems(searchValue, items) : items;
  }, [searchValue]);

  const onRadioChange = (value: string) => {
    setSelectedValue(value);
    setTriggerValue(findItemByValue(value, items)?.label ?? emptyTriggerValue);
    onChange(value);
  };

  return (
    <CmdDropdown.Menu>
      <CmdDropdown.SelectTrigger style={{ width: triggerWidth }}>
        {triggerValue ? triggerValue : emptyTriggerValue}
      </CmdDropdown.SelectTrigger>
      <CmdDropdown.Content
        className="overflow-y-auto"
        style={{ maxHeight: contentMaxHeight, width: 'var(--radix-dropdown-menu-trigger-width)' }}
      >
        {searchable && (
          <>
            <CmdInput
              placeholder={searchPlaceholder}
              value={searchValue}
              onKeyDown={(e) => {
                // Preventing propagation prevents native input behavior from stealing focus while searching
                e.stopPropagation();
                // Typing up or down arrow keys should move focus to the appropriate input
                if (['ArrowDown', 'ArrowUp'].includes(e.code)) {
                  const roleItems = radioGroupRef.current?.querySelectorAll<HTMLInputElement>('[role]');
                  if (e.code === 'ArrowDown') {
                    roleItems?.[0]?.focus();
                  } else {
                    roleItems?.[roleItems.length - 1]?.focus();
                  }
                }
              }}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              inline={true}
              autoFocus={true}
              className="px-none"
            />
            <CmdDropdown.Separator />
          </>
        )}
        <CmdDropdown.RadioGroup value={selectedValue} onValueChange={onRadioChange} ref={radioGroupRef}>
          {filteredMatches.length >= 1
            ? renderItems(filteredMatches)
            : emptyState && <CmdDropdown.Item>{emptyState}</CmdDropdown.Item>}
        </CmdDropdown.RadioGroup>
      </CmdDropdown.Content>
    </CmdDropdown.Menu>
  );
};

export { CmdCombobox };
