import { cva } from 'class-variance-authority';

export const alertStyles = cva(
  'relative w-full border p-4 flex gap-x-2 [&>svg]:flex-none [&>svg]:relative [&>svg]:top-[1px] [&>svg]:text-foreground',
  {
    variants: {
      variant: {
        info: 'bg-blue200 text-blue900 border-blue-300',
        success: 'bg-green200 text-green800 border-green-300',
        warning: 'bg-orange200 text-orange900 border-orange-900',
        error: 'bg-red200 text-red900',
      },
    },
    defaultVariants: {
      variant: 'info',
    },
  },
);
