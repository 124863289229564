/** @jsx jsx */
/** @jsxFrag React.Fragment */

import {
  CmdSelect,
  CmdButton,
  CmdInput,
  CmdLabel,
  CmdTooltip,
  CmdAccordion,
  CmdTextarea,
} from '@commandbar/design-system/cmd';
import { Lock02, ReverseLeft } from '@commandbar/design-system/icons/react';
import React, { useCallback, useEffect, useState } from 'react';
import { ComponentThemeFieldGroup, EditableThemeField, ThemeFieldGroup } from './editableFieldTrees';
import {
  categoryHasBeenUpdated,
  getColorAndOpacity,
  getDisplayValue,
  getVariableValue,
  handleColorInput,
  isANumber,
  variableHasBeenUpdated,
} from './helpers';
import { IconContainer } from '../nudges/styled';
import { ColorSwatch } from './shared';
import { IThemeType, IThemeV2Type } from '@commandbar/internal/middleware/types';
import { useAppContext } from 'editor/src/AppStateContext';
import { ColorPicker } from './ColorPicker';
import { debounce } from 'lodash';
import { DEFAULT_THEME } from '@commandbar/internal/client/themesV2/defaults';
import { jsx, css } from '@emotion/core';
import { AnimationEditor } from './AnimationEditor';
import styled from '@emotion/styled';

const ThemeFieldGroupDisplay = ({
  theme,
  mode,
  revertVariable,
  revertAllVariables,
  handleSave,
  fieldGroup,
  parentSlug,
  stateSlug,
}: {
  theme: IThemeType | undefined;
  mode: 'light_mode' | 'dark_mode';
  revertVariable: (
    variable: string | undefined,
    parentSlug?: string,
    stateSlug?: string,
    currentDraftTheme?: IThemeV2Type,
  ) => IThemeV2Type;
  revertAllVariables: (category: ThemeFieldGroup, parentSlug?: string, stateSlug?: string) => void;
  handleSave: (varOverrides: Record<string, string>, parentSlug?: string, stateSlug?: string) => void;
  fieldGroup: ThemeFieldGroup;
  parentSlug?: string;
  stateSlug?: string;
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        padding: '16px',
      }}
    >
      {fieldGroup.children.map((child) =>
        'variable' in child ? (
          <ThemeFieldEditor
            key={child.label + '-' + theme?.slug}
            theme={theme}
            mode={mode}
            field={child}
            revertVariable={revertVariable}
            handleSave={handleSave}
            parentSlug={parentSlug}
            stateSlug={stateSlug}
          />
        ) : (
          <ThemeAccordion
            key={child.label + '-' + theme?.slug}
            theme={theme}
            mode={mode}
            category={child}
            revertVariable={revertVariable}
            revertAllVariables={revertAllVariables}
            handleSave={handleSave}
            height="40px"
          />
        ),
      )}
    </div>
  );
};

export const ThemeAccordion = ({
  theme,
  mode,
  category,
  revertVariable,
  revertAllVariables,
  handleSave,
  parentSlug,
  stateSlug,
  defaultValue,
  height = '48px',
}: {
  theme: IThemeType | undefined;
  mode: 'light_mode' | 'dark_mode';
  category: ThemeFieldGroup;
  revertVariable: (
    variable: string | undefined,
    parentSlug?: string,
    stateSlug?: string,
    currentDraftTheme?: IThemeV2Type,
  ) => IThemeV2Type;
  revertAllVariables: (category: ThemeFieldGroup, parentSlug?: string, stateSlug?: string) => void;
  handleSave: (varOverrides: Record<string, string>, parentSlug?: string, stateSlug?: string) => void;
  parentSlug?: string;
  stateSlug?: string;
  defaultValue?: string;
  height?: string;
}) => {
  const hasUpdatedField = categoryHasBeenUpdated(theme, mode, category);
  const { organizationSettings } = useAppContext();

  const isDisabled =
    organizationSettings?.skins_field_set !== 'pro' && category.slug !== 'brand' && category.slug !== 'typography';
  const hideResetArrow = isDisabled || category.slug === 'typography';

  const renderAccordionTrigger = () => (
    <CmdAccordion.Trigger style={{ height: height, ...(isDisabled && { cursor: 'not-allowed' }) }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'space-between',
          gap: '4px',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '4px',
          }}
        >
          <span style={{ userSelect: 'none' }}>{category.label}</span>
          {isDisabled && <Lock02 />}
        </div>

        {!hideResetArrow && hasUpdatedField && (
          <CmdButton
            variant="link"
            icon={<ReverseLeft />}
            onClick={(e) => {
              e.stopPropagation();
              revertAllVariables(category);
            }}
            tooltip="Reset"
          />
        )}
      </div>
    </CmdAccordion.Trigger>
  );

  return (
    <CmdAccordion collapsible defaultValue={defaultValue} disabled={isDisabled}>
      <CmdAccordion.Item value={category.label}>
        {isDisabled ? (
          <CmdTooltip message="Upgrade to access">{renderAccordionTrigger()}</CmdTooltip>
        ) : (
          renderAccordionTrigger()
        )}
        <CmdAccordion.Content style={{ padding: '0px', overflow: 'visible' }}>
          <ThemeFieldGroupDisplay
            theme={theme}
            mode={mode}
            revertVariable={revertVariable}
            revertAllVariables={revertAllVariables}
            handleSave={handleSave}
            fieldGroup={category}
            parentSlug={parentSlug}
            stateSlug={stateSlug}
          />
        </CmdAccordion.Content>
      </CmdAccordion.Item>
    </CmdAccordion>
  );
};

const StyledThemeFieldEditorContainer = styled.div`
  position: relative;
  display: grid;
  ${(props: { formType: 'input' | 'textarea' }) =>
    props.formType !== 'textarea' &&
    `
    grid-template-columns: 50% 50%;
    justify-content: space-between;
  `}
`;

export const ThemeFieldEditor = ({
  theme,
  mode,
  field,
  revertVariable,
  handleSave,
  parentSlug,
  stateSlug,
}: {
  theme: IThemeType | undefined;
  mode: 'light_mode' | 'dark_mode';
  field: EditableThemeField;
  revertVariable: (
    variable: string | undefined,
    parentSlug?: string,
    stateSlug?: string,
    currentDraftTheme?: IThemeV2Type,
  ) => IThemeV2Type;
  handleSave: (varOverrides: Record<string, string>, parentSlug?: string, stateSlug?: string) => void;
  parentSlug?: string;
  stateSlug?: string;
}) => {
  const value = getVariableValue(theme, mode, field.variable, parentSlug, stateSlug);
  const [displayValue, setDisplayValue] = useState<{ value: string | undefined; opacity?: number }>();
  const [autoUpdateDisplayValue, setAutoUpdateDisplayValue] = useState(true);
  const hasBeenUpdated = variableHasBeenUpdated(theme?.themeV2_draft, mode, field.variable, parentSlug, stateSlug);
  const [showColorPicker, setShowColorPicker] = useState(false);

  useEffect(() => {
    const widget = field.type === 'animation' ? field.widget : undefined;
    if (autoUpdateDisplayValue) setDisplayValue(getDisplayValue(theme, mode, field.type, value, widget));
  }, [theme, value]);

  const debouncedSave = useCallback(
    debounce((newColor, opacity) => {
      handlePropertyChange(newColor, opacity);
    }, 15),
    [handleSave, field, parentSlug, stateSlug],
  );

  const handlePropertyChange = (value: string, opacity?: number) => {
    let updatedValue: string | undefined = value;

    if (field.type === 'size' && isANumber(value)) {
      updatedValue = value + 'px';
    }

    if (field.type === 'color') {
      updatedValue = handleColorInput(value, opacity === undefined || isNaN(opacity) ? 100 : opacity);
    }

    handleSave({ [field.variable]: updatedValue }, parentSlug, stateSlug);
  };

  return (
    <>
      {field.type === 'animation' && (
        <AnimationEditor
          theme={theme}
          field={field}
          mode={mode}
          revertVariable={revertVariable}
          onBlur={() => setAutoUpdateDisplayValue(true)}
          onChange={(changes) => {
            setAutoUpdateDisplayValue(true);
            handleSave(changes);
          }}
        />
      )}
      {field.type === 'custom' && (
        <field.customField
          field={field}
          value={displayValue?.value}
          onChange={(value) => {
            handlePropertyChange(value, displayValue?.opacity);
          }}
        />
      )}
      {field.type !== 'animation' && field.type !== 'custom' && (
        <StyledThemeFieldEditorContainer formType={field.formType}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CmdTooltip message={field.label}>
              <CmdLabel
                style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', userSelect: 'none' }}
              >
                {field.label}
              </CmdLabel>
            </CmdTooltip>
            <CmdLabel tooltip={field.tooltip} />
          </div>

          {field.formType === 'textarea' ? (
            <CmdTextarea
              fullWidth
              value={displayValue?.value}
              onChange={(e) => {
                setAutoUpdateDisplayValue(false);
                handlePropertyChange(e.target.value, displayValue?.opacity);
                setDisplayValue((displayValue) => {
                  return { ...displayValue, value: e.target.value };
                });
              }}
              onBlur={() => setAutoUpdateDisplayValue(true)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.currentTarget.blur();
                }
              }}
              style={{
                minHeight: '64px',
                marginTop: '4px',
              }}
            />
          ) : (
            <CmdInput
              fullWidth
              prefixElement={
                field.type === 'color' ? (
                  <div
                    style={{ position: 'relative' /* enables us to position the color picker relative to this div */ }}
                  >
                    <IconContainer>
                      <ColorSwatch
                        color={getColorAndOpacity(theme, mode, value).rgb}
                        opacity={getColorAndOpacity(theme, mode, value).opacity}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowColorPicker((show) => !show);
                        }}
                      />
                    </IconContainer>
                    {showColorPicker && value && (
                      <ColorPicker
                        initialColor={value}
                        position={{ top: 32, left: 0 }}
                        onClose={() => {
                          // HACK: use "setTimeout" here to workaround a bug where the color picker
                          //  would re-open immediately after closing when you clicked the color swatch
                          //  to close it. -JL 2024-04-22
                          setTimeout(() => setShowColorPicker(false), 0);
                        }}
                        onChange={(newColor: string) => {
                          debouncedSave(newColor, displayValue?.opacity);
                        }}
                      />
                    )}
                  </div>
                ) : field.icon ? (
                  <IconContainer>{field.icon}</IconContainer>
                ) : undefined
              }
              suffixElement={
                hasBeenUpdated ? (
                  <CmdButton
                    style={{ height: '20px', padding: '4px' }}
                    variant="link"
                    icon={<ReverseLeft />}
                    onClick={() => revertVariable(field.variable, parentSlug, stateSlug)}
                    tooltip="Reset"
                  />
                ) : (
                  <div className="w-0"></div>
                )
              }
              value={displayValue?.value}
              secondValue={
                displayValue?.opacity !== undefined
                  ? isNaN(displayValue?.opacity)
                    ? '%'
                    : displayValue?.opacity + '%'
                  : undefined
              }
              onChange={(e) => {
                setAutoUpdateDisplayValue(false);
                handlePropertyChange(e.target.value, displayValue?.opacity);
                setDisplayValue((displayValue) => {
                  return { ...displayValue, value: e.target.value };
                });
              }}
              onSecondValueChange={
                displayValue?.opacity !== undefined
                  ? (e) => {
                      setAutoUpdateDisplayValue(false);
                      displayValue?.value &&
                        !isNaN(parseFloat(e.target.value)) &&
                        handlePropertyChange(displayValue?.value, parseInt(e.target.value));
                      setDisplayValue((displayValue) => {
                        return {
                          value: displayValue?.value,
                          opacity: parseInt(e.target.value),
                        };
                      });
                    }
                  : undefined
              }
              onBlur={() => setAutoUpdateDisplayValue(true)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.currentTarget.blur();
                }
              }}
            />
          )}
        </StyledThemeFieldEditorContainer>
      )}
    </>
  );
};

const ThemeComponentEditor = ({
  theme,
  mode,
  component,
  revertVariable,
  revertAllVariables,
  handleSave,
}: {
  theme: IThemeType | undefined;
  mode: 'light_mode' | 'dark_mode';
  component: ComponentThemeFieldGroup;
  revertVariable: (
    variable: string | undefined,
    parentSlug?: string,
    stateSlug?: string,
    currentDraftTheme?: IThemeV2Type,
  ) => IThemeV2Type;
  revertAllVariables: (category: ThemeFieldGroup, parentSlug?: string, stateSlug?: string) => void;
  handleSave: (varOverrides: Record<string, string>, parentSlug?: string, stateSlug?: string) => void;
}) => {
  const [currState, setCurrState] = useState('default');
  const currentFieldGroup = component?.children?.find((state) => state.slug === currState);
  const hasBeenUpdated =
    currentFieldGroup && categoryHasBeenUpdated(theme, mode, currentFieldGroup, component.component.slug, currState);
  const [componentActive, setComponentActive] = useState(false);

  const fontOverridesString = `${
    theme?.themeV2_draft?.[mode].var_overrides['--font-font-family']
      ? `${theme?.themeV2_draft?.[mode].var_overrides['--font-font-family']}, `
      : theme?.themeV2_draft?.light_mode.var_overrides['--font-font-family']
      ? `${theme?.themeV2_draft?.light_mode.var_overrides['--font-font-family']}, `
      : ''
  }${DEFAULT_THEME.light_mode.var_defaults['--font-font-family']}`;

  return (
    <div
      style={{
        width: '100%',
        borderRadius: '8px',
        border: '1px solid rgba(0, 0, 0, 0.08)',
        background: '#FFF',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          gap: '8px',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
          padding: '8px 16px',
        }}
      >
        <span>{component.label}</span>
        <div style={{ display: 'flex', gap: '8px', justifyContent: 'center', alignItems: 'center' }}>
          <CmdSelect.Menu value={currState} onValueChange={(value) => setCurrState(value)}>
            <CmdSelect.SelectTrigger style={{ gap: '8px', width: '140px', border: '1px solid rgba(0, 0, 0, 0.16)' }}>
              <CmdSelect.SelectValue placeholder={currState} />
            </CmdSelect.SelectTrigger>

            <CmdSelect.SelectContent>
              {component.children.map((state) => (
                <CmdSelect.SelectItem key={state.slug} value={state.slug}>
                  <div
                    style={{
                      display: 'flex',
                      gap: '8px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '14px',
                    }}
                  >
                    {state.icon}
                    {state.label}
                  </div>
                </CmdSelect.SelectItem>
              ))}
            </CmdSelect.SelectContent>
          </CmdSelect.Menu>
          {hasBeenUpdated ? (
            <CmdButton
              variant="link"
              icon={<ReverseLeft />}
              onClick={(e) => {
                e.stopPropagation();
                revertAllVariables(currentFieldGroup, component.component.slug, currState);
              }}
              tooltip="Reset"
            />
          ) : (
            <div style={{ width: '42px' }}></div>
          )}
        </div>
      </div>

      <div
        style={{
          display: 'grid',
          justifyContent: 'space-between',
          gridTemplateColumns: '65% 35%',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRight: '1px solid rgba(0, 0, 0, 0.08)',
            height: '400px',
            padding: '48px 24px',
            backgroundColor: mode === 'light_mode' ? '#F9F9F9' : '#3F424A',
            backgroundImage:
              'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABZSURBVHgB7dG7DYBADANQJxexAiXSTXw9uyExBih34SOxQhr83Li2ASIiIiIiIvo7QbLW1jms1zijq9puSDaGLxhhuBPaqyKZTFK+/q6AZOJlg8shqv5ccAGZWRnaKiSy9QAAAABJRU5ErkJggg==)',
          }}
        >
          <div
            style={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '200px',
            }}
            css={css`
              --font-font-family: ${fontOverridesString};
            `}
          >
            {component.component.renderMock(theme?.themeV2_draft, mode, componentActive, () =>
              setComponentActive((active) => !active),
            )}
          </div>
        </div>
        {currentFieldGroup && (
          <ThemeFieldGroupDisplay
            theme={theme}
            mode={mode}
            revertVariable={revertVariable}
            revertAllVariables={revertAllVariables}
            handleSave={handleSave}
            fieldGroup={currentFieldGroup}
            parentSlug={component.component.slug}
            stateSlug={currState}
          />
        )}
      </div>
    </div>
  );
};

export default ThemeComponentEditor;
