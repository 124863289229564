import React, { useState } from 'react';

import styled, { ComponentSelector } from '@emotion/styled';

import { CB_COLORS, Table as AntdTable } from '@commandbar/design-system/components/antd';

// TODO: refactor with ChatTable in commandbar.com/src/components/analytics/Analytics/HelpHubChat/index.tsx
const Table = styled(AntdTable)`
  display: flex;
  flex: 1 0 0;
  border-right: 1px solid ${CB_COLORS.neutral300};

  & .ant-spin-container {
    height: 100%;
  }

  & .ant-table-expand-icon-col {
    width: 24px;
  }

  & .ant-table {
    border-top: none;
    background: #fff;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.05);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  & .ant-table-expanded-row .ant-table-cell {
    padding: 8px 4px;
    padding-top: 0;
    padding-bottom: 0;
  }

  & .ant-table-container {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  & .ant-table-row {
    cursor: pointer;
  }

  & .hover-button {
    display: none;
  }

  & .ant-table-cell-row-hover .hover-button {
    display: flex;
  }

  & .ant-table-row.selected,
  .ant-table-row.selected .ant-table-cell-row-hover {
    background: #e6ecfe !important;
  }

  & .ant-table-tbody > tr:hover {
    background: #f9f9f9;
  }

  & .ant-table-cell {
    padding: 4px;
    align-items: center;
    border-bottom: none;
    color: ${CB_COLORS.neutral800};
    font-size: 14px;
    font-weight: 500;
  }

  & .ant-table-cell.options {
    padding: 0;
  }

  & .ant-table-thead .ant-table-cell {
    padding: 9px 4px 9px 4px;
    border-bottom: 1px solid ${CB_COLORS.neutral300};
    background: ${CB_COLORS.neutral0};
    color: ${CB_COLORS.neutral600};
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    text-transform: none;

    &::before {
      background-color: transparent !important;
    }
  }

  & .ant-table-footer {
    padding: 0;
  }
` as unknown as typeof AntdTable & ComponentSelector;

const FooterContainer = styled.div`
  display: flex;
  padding: 8px 8px 8px 12px;
  justify-content: space-between;
  align-items: center;
  background: ${CB_COLORS.neutral0};
  border-radius: 4px;
`;

const RowCount = styled.div<{ show: boolean }>`
  color: ${({ show }) => (show ? CB_COLORS.neutral800 : 'transparent')};
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
`;

const PaginationContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const PaginationButton = styled.button<{ disabled: boolean }>`
  all: unset;
  display: flex;
  height: 6px;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid rgba(10, 10, 15, 0.24);
  background: #fff;
  box-shadow: 0px -2px 0px 0px rgba(0, 0, 0, 0.07) inset, 0px 1px 3px 0px rgba(0, 0, 0, 0.07);
  color: #000;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '50%' : '100%')};
`;
interface FooterProps {
  totalItems: number;
  pageSize: number;
  currentPage: number;

  gotoNextPage: () => void;
  gotoPreviousPage: () => void;
}

export const Footer = ({ totalItems, currentPage, pageSize, gotoNextPage, gotoPreviousPage }: FooterProps) => {
  const startRange = pageSize * currentPage + 1;
  const endRange = Math.min(totalItems, startRange + pageSize - 1);
  const hasPreviousPage = currentPage > 0;
  const hasNextPage = totalItems > endRange;

  return (
    <FooterContainer>
      <RowCount show={!!totalItems}>
        <span>
          {startRange} - {endRange}
        </span>
        <span style={{ fontWeight: 400 }}> of </span>
        <span>{totalItems}</span>
      </RowCount>

      <PaginationContainer>
        <PaginationButton disabled={!hasPreviousPage} onClick={gotoPreviousPage}>
          {'<- Prev'}
        </PaginationButton>
        <PaginationButton disabled={!hasNextPage} onClick={gotoNextPage}>
          {'Next ->'}
        </PaginationButton>
      </PaginationContainer>
    </FooterContainer>
  );
};

export const SimplePaginatedTable = <RecordType extends object>({
  dataSource = [],
  pageSize,
  ...props
}: React.ComponentProps<typeof AntdTable<RecordType>> & { pageSize: number }) => {
  const [currentPage, setCurrentPage] = useState(0);

  const startRange = pageSize * currentPage;
  const endRange = startRange + pageSize;

  const footer = () => (
    <Footer
      totalItems={dataSource.length}
      pageSize={pageSize}
      currentPage={currentPage}
      gotoNextPage={() => setCurrentPage((p) => p + 1)}
      gotoPreviousPage={() => setCurrentPage((p) => p - 1)}
    />
  );
  return (
    <Table
      footer={footer}
      pagination={false /* NOTE: we handle pagination ourselves above, so disable built-in pagination handling */}
      dataSource={dataSource.slice(startRange, endRange)}
      {...props}
    />
  );
};

export const SimpleNonPaginatedTable = <RecordType extends object>({
  dataSource = [],
  ...props
}: React.ComponentProps<typeof AntdTable<RecordType>>) => {
  return (
    <Table
      style={{ borderRadius: '8px', border: '1px solid #EBEBEB' }}
      scroll={{ y: 'calc(100vh - 232px)' }}
      pagination={false /* NOTE: we handle pagination ourselves above, so disable built-in pagination handling */}
      dataSource={dataSource}
      {...props}
    />
  );
};
