import * as React from 'react';
import { type VariantProps } from 'class-variance-authority';
import { cn } from '../util';
import { alertStyles } from './styles';
import { CmdTypography, TypographyProps } from '../typography';

const Alert = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertStyles>>(
  ({ className, variant, ...props }, ref) => (
    <div ref={ref} role="alert" className={cn(alertStyles({ variant }), className)} {...props} />
  ),
);
Alert.displayName = 'Alert';

const AlertTitle = ({ className, ...props }: TypographyProps) => {
  return <CmdTypography.Body className={cn('text-inherit leading-snug tracking-tight', className)} {...props} />;
};
AlertTitle.displayName = 'AlertTitle';

const AlertDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn('text-sm [&_p]:leading-relaxed', className)} {...props} />
  ),
);
AlertDescription.displayName = 'AlertDescription';

export { Alert, AlertTitle, AlertDescription };
