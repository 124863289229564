import React from 'react';

import { Form, Input } from '@commandbar/design-system/components/antd';
import { FormLayout2 } from './FormLayout';
import Auth from '@commandbar/internal/client/authentication';
import { useHistory } from 'react-router';
import Sender from '../management/Sender';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import { CmdButton, CmdDivider } from '@commandbar/design-system/cmd';
import { GoogleBrand } from '@commandbar/design-system/icons/react';

const Login = () => {
  const { login, error, setError } = useAuth();
  const history = useHistory();
  const [authType, setAuthType] = React.useState('unknown');
  const [loading, setLoading] = React.useState<boolean>(false);

  const isDev = process.env.IS_PREVIEW || process.env.REACT_APP_API_URL === 'https://api-dev.commandbar.com';

  const [emailValidateType, setEmailValidateType] = React.useState('onBlur');

  const checkAuthType = async (email: string) => {
    try {
      const data = await Auth.getLoginType(email);
      setAuthType(data);

      if (data === 'google') {
        signInWithGoogle();
      }
    } catch {
      setAuthType('basic');
    }
  };

  const onFinish = async (values: any) => {
    if (authType === 'unknown') {
      checkAuthType(values.email);
    } else {
      setLoading(true);
      await login(values.email, values.password);
      setLoading(false);
    }
  };

  const signInWithGoogle = async () => {
    setLoading(true);

    const authorization_url = await Auth.google({
      redirect_uri: window.location.origin,
      is_signup: false,
    });

    if (!authorization_url) {
      setError('Something went wrong. Please try again.');
      setLoading(false);
    } else {
      Sender.secureEditor();
      window.open(authorization_url);
    }
    setLoading(false);
  };

  return (
    <FormLayout2 error={error}>
      <Form layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Email"
          name="email"
          id="email"
          validateTrigger={emailValidateType}
          rules={[{ type: 'email', max: 30, message: 'Invalid Email Address' }]}
        >
          <Input
            onBlur={() => setEmailValidateType('onChange')}
            placeholder="Enter your email"
            type="email"
            autoComplete="email"
          />
        </Form.Item>

        {authType === 'basic' && (
          <Form.Item label="Password">
            <Form.Item name="password" noStyle>
              <Input.Password placeholder="Enter your password" autoFocus />
            </Form.Item>
            <div style={{ textAlign: 'end' }}>
              <a
                href={`${process.env.REACT_APP_AUTH_URL}/auth/password_reset/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Forgot your password?
              </a>
            </div>
          </Form.Item>
        )}

        <Form.Item style={{ margin: '0px' }}>
          <CmdButton fullWidth variant="primary" type="submit" disabled={loading}>
            {loading ? '...' : 'Continue'}
          </CmdButton>
        </Form.Item>
      </Form>
      <CmdDivider spacing="sm" orientation="center">
        OR
      </CmdDivider>
      {!isDev && (
        <>
          <CmdButton icon={<GoogleBrand />} fullWidth onClick={signInWithGoogle}>
            Sign in with Google
          </CmdButton>
          <CmdButton onClick={() => history.push('/login/sso')} fullWidth>
            Sign in with SSO
          </CmdButton>
        </>
      )}

      <div style={{ padding: '24px 20% 0px', overflowWrap: 'break-word', color: '#8e8e8e', textAlign: 'center' }}>
        By signing in, you agree to our{' '}
        <a href="https://commandbar.com/terms" target="_blank" rel="noopener noreferrer">
          Terms of Service
        </a>{' '}
        and{' '}
        <a href="https://commandbar.com/privacy" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
      </div>
    </FormLayout2>
  );
};

export default Login;
