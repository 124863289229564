import { DashedBorderWrapper, Modal, SortableList } from '@commandbar/design-system/components/antd';
import React, { useEffect } from 'react';
import { AlertTriangle, Plus } from '@commandbar/design-system/icons/react';
import useChatSettings from '../useEditor/useChatSettings';
import { ICopilotFallback } from '@commandbar/internal/middleware/types';
import { CmdButton, cmdToast } from '@commandbar/design-system/cmd';
import FallbackForm from './FallbackForm';

interface FallbackSettingsProps {
  handleDisplayTooltipFallback: (copilotFallbacks: ICopilotFallback[]) => void;
}

const FallbackSettings = ({ handleDisplayTooltipFallback }: FallbackSettingsProps) => {
  const [activeKeys, setActiveKeys] = React.useState<string[]>([]);
  const { copilotFallbacks, actions } = useChatSettings();

  const handleSaveFallback = async (updatedFallback: ICopilotFallback) => {
    try {
      await actions.copilotFallbacks.update(updatedFallback);
      cmdToast.success('Fallback saved');
    } catch (error) {
      cmdToast.error('Failed to save fallback');
    }
  };

  useEffect(() => {
    handleDisplayTooltipFallback(copilotFallbacks);
  }, [copilotFallbacks]);

  const handleDeleteFallback = (id: number, index: number) => {
    Modal.confirm({
      icon: (
        <AlertTriangle
          height={22}
          width={22}
          color="#FAAD14"
          style={{
            float: 'left',
            marginRight: '16px',
          }}
        />
      ),
      title: 'Are you sure you want to delete this fallback? This cannot be undone.',
      async onOk() {
        setActiveKeys((keys) => keys.filter((key) => key !== `fallback-${index}`));
        actions.copilotFallbacks.delete(id);
      },
    });
  };

  const handleAddFallback = () => {
    const newFallback: ICopilotFallback = {
      id: -1, // Temporary ID for new fallbacks
      title: 'New Fallback',
      trigger_event: 'no_answer',
      audience: { type: 'all_users' },
      message: '',
      ctas: [],
      is_default: false,
      order_key: copilotFallbacks.length,
    };
    actions.copilotFallbacks.create(newFallback);
    setActiveKeys((keys) => [...keys, `fallback-${copilotFallbacks.length}`]);
  };

  const toggleActiveKey = (index: number) => {
    setActiveKeys((keys) => {
      return activeKeys.includes(`fallback-${index}`)
        ? keys.filter((key) => key !== `fallback-${index}`)
        : [...keys, `fallback-${index}`];
    });
  };

  const orderderedFallbacks = [...copilotFallbacks].sort((a, b) => a.order_key - b.order_key);

  const handleSort = (oldIndex: number, newIndex: number) => {
    const newFallbacks = [...orderderedFallbacks];
    const [movedItem] = newFallbacks.splice(oldIndex, 1);
    newFallbacks.splice(newIndex, 0, movedItem);

    newFallbacks.forEach((fallback, index) => {
      actions.copilotFallbacks.update({
        ...fallback,
        order_key: index,
      });
    });
  };

  return (
    <div>
      <SortableList
        nodes={orderderedFallbacks.map((fallback, index) => {
          return (
            <FallbackForm
              key={`fallback-${index}`}
              fallback={fallback}
              index={index}
              activeKeys={activeKeys}
              handleSaveFallback={handleSaveFallback}
              handleDeleteFallback={handleDeleteFallback}
              toggleActiveKey={toggleActiveKey}
            />
          );
        })}
        onSort={handleSort}
      />
      <DashedBorderWrapper
        style={{
          background: '#F9F9F9',
        }}
      >
        <CmdButton icon={<Plus />} onClick={handleAddFallback}>
          Add fallback
        </CmdButton>
      </DashedBorderWrapper>
    </div>
  );
};

export default FallbackSettings;
