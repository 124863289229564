import React, { useEffect, useState } from 'react';

import { User01, UsersX, UsersCheck } from '@commandbar/design-system/icons/react';
import { CmdColumnDef, CmdDataTable, CmdDropdown } from '@commandbar/design-system/cmd';
import { INudgeType, IRuleExpression } from '@commandbar/internal/middleware/types';
import { Rule, AudienceTableData, AudienceFilter } from '@commandbar/internal/middleware/rule';
import { useAppContext } from 'editor/src/AppStateContext';

type Filter = {
  value: AudienceFilter;
  title: string;
  icon: React.ReactNode;
};

const PAGE_SIZE = 10;

const filterOptions: Filter[] = [
  {
    value: 'eligible',
    title: 'Eligible users',
    icon: <User01 />,
  },
  {
    value: 'ineligible',
    title: 'Ineligible users',
    icon: <UsersX />,
  },
  {
    value: 'completed',
    title: 'Completed',
    icon: <UsersCheck />,
  },
];

const AudienceTable = ({
  audience,
  id,
  isNudge,
}: {
  audience: INudgeType['audience'];
  id: number | string;
  isNudge: boolean;
}) => {
  const { rules } = useAppContext();

  const [selectedFilter, setSelectedFilter] = useState<AudienceFilter>(filterOptions[0].value);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<'InvalidOrEmptyConditionGroup' | 'NotImplementedError' | string | null>(
    null,
  );
  const [audienceDetails, setAudienceDetails] = React.useState<AudienceTableData | undefined>(undefined);
  const [page, setPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');

  const fetchAudienceDetails = async (expr: IRuleExpression | null) => {
    setLoading(true);
    const payload = {
      expr: expr,
      id: id,
      is_nudge: isNudge,
      page: page,
      page_size: PAGE_SIZE,
      search_filter: searchQuery,
      filter: selectedFilter,
    };

    let cb = Rule.readEligible;

    if (selectedFilter === 'eligible') {
      cb = Rule.readEligible;
    } else if (selectedFilter === 'ineligible') {
      cb = Rule.readIneligible;
    } else if (selectedFilter === 'completed') {
      cb = Rule.readUserActions;
    }

    await cb(payload)
      .then((res) => setAudienceDetails(res.users))
      .catch((error) => {
        setAudienceDetails(undefined);

        if ('code' in (error as { code: string })) {
          setError((error as { code: string }).code);
          return;
        }

        setError('Something went wrong');
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchAudienceDetails(
      audience?.type === 'rule_expression'
        ? audience.expression
        : audience?.type === 'named_rule_reference'
        ? rules.filter(
            (rule) => audience?.type === 'named_rule_reference' && audience.rule_reference.rule_id === rule.id,
          )[0].expression
        : null,
    );
  }, [audience, searchQuery, page, selectedFilter]);

  useEffect(() => {
    setPage(0);
  }, [selectedFilter, searchQuery]);

  const details = filterOptions.filter((option) => option.value === selectedFilter)[0];

  const columns: CmdColumnDef<any, any>[] = [
    {
      accessorKey: 'end_user',
      header: 'User id',
      enableGlobalFilter: true,
      cell: ({ cell }) => <>{cell.renderValue() || 'Anonymous User'}</>,
    },
    ...(selectedFilter === 'completed' ? [{ accessorKey: 'action', header: 'Action', enableGlobalFilter: true }] : []),
  ];

  return (
    <>
      <CmdDataTable
        manualPagination
        columns={columns}
        state={{
          globalFilter: searchQuery,
          pagination: {
            pageIndex: page,
            pageSize: PAGE_SIZE,
          },
        }}
        onGlobalFilterChange={(value) => {
          setSearchQuery(value);
        }}
        onPaginationChange={(updater) => {
          if (typeof updater === 'function') {
            const newState = updater({
              pageSize: PAGE_SIZE,
              pageIndex: page,
            });

            setPage(newState.pageIndex);
          } else {
            setPage(updater.pageIndex);
          }
        }}
        rowCount={audienceDetails?.total}
        pageCount={Math.ceil((audienceDetails?.total || PAGE_SIZE) / PAGE_SIZE)}
        isLoading={loading}
        emptyResultChildren={error ? <>There was an error.</> : <>No results</>}
        data={audienceDetails?.users || []}
        toolBarChildren={
          <CmdDropdown.Menu>
            <CmdDropdown.SelectTrigger style={{ width: '100%', marginRight: '8px' }}>
              {details.icon}
              {details.title}
            </CmdDropdown.SelectTrigger>
            <CmdDropdown.Content style={{ width: '200px' }}>
              <CmdDropdown.RadioGroup
                value={selectedFilter}
                onValueChange={(value) => setSelectedFilter(value as Filter['value'])}
              >
                {filterOptions.map((option) => (
                  <>
                    <CmdDropdown.RadioItem value={option.value}>
                      <span style={{ gap: '8px', display: 'flex', alignContent: 'center' }}>
                        {option.icon}
                        {option.title}
                      </span>
                    </CmdDropdown.RadioItem>

                    {option.value === 'ineligible' && <CmdDropdown.Separator />}
                  </>
                ))}
              </CmdDropdown.RadioGroup>
            </CmdDropdown.Content>
          </CmdDropdown.Menu>
        }
      />
    </>
  );
};

export default AudienceTable;
