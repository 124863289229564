import React from 'react';
import styled from '@emotion/styled';

import { NudgeDropdown } from './NudgeDropdown';

import { ReactComponent as ModalSvg } from '../../../img/nudges/modal.svg';
import { ReactComponent as PopoverSvg } from '../../../img/nudges/popover.svg';
import { ReactComponent as PinSvg } from '../../../img/nudges/pin.svg';
import { ReactComponent as TooltipSvg } from '../../../img/nudges/tooltip.svg';
import { ReactComponent as BannerSvg } from '../../../img/nudges/banner.svg';

import type { INudgeStepType } from '@commandbar/internal/middleware/types';

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

type FormFactorType = INudgeStepType['form_factor']['type'];

type FormFactorOption = {
  label: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

export const FORM_FACTORS: Record<FormFactorType, FormFactorOption> = {
  modal: {
    label: 'Modal',
    Icon: ModalSvg,
  },
  popover: {
    label: 'Popover',
    Icon: PopoverSvg,
  },
  pin: {
    label: 'Pin',
    Icon: PinSvg,
  },
  tooltip: {
    label: 'Tooltip',
    Icon: TooltipSvg,
  },
  banner: {
    label: 'Banner',
    Icon: BannerSvg,
  },
};

const FORM_FACTOR_OPTIONS = (Object.keys(FORM_FACTORS) as FormFactorType[]).map((type) => ({
  label: FORM_FACTORS[type].label,
  value: type,
}));

export const DISPLAY_VALUE_GAP = '10px';
export interface IFormFactorSelect {
  onChange: (type: FormFactorType) => void;
  value: FormFactorType;
  exclude: FormFactorType[];
}

export const FormFactorDropdown = ({ onChange, value, exclude }: IFormFactorSelect) => {
  const { Icon, label } = FORM_FACTORS[value];

  const filteredOptions = FORM_FACTOR_OPTIONS.filter((option) => !exclude.includes(option.value));

  return (
    <NudgeDropdown
      gap={DISPLAY_VALUE_GAP}
      options={[filteredOptions]}
      onChange={onChange}
      value={value}
      placement="bottomLeft"
    >
      <IconContainer>
        <Icon />
      </IconContainer>
      {label}
    </NudgeDropdown>
  );
};
