import * as t from 'io-ts';
import { readObject, read } from './generics';
import { PaginationFilter, SortParams } from './analytics/common';

export const EndUserAdminV = t.type(
  {
    id: t.number,
    created: t.string,
    modified: t.string,
    slug: t.string,
    user_properties: t.record(t.string, t.unknown),
    last_seen_at: t.string,
    num_sessions: t.number,
  },
  'EndUser',
);

export const EndUserPropertiesV = t.type({
  keys: t.array(t.string),
});

export const CustomEventsV = t.type({
  names: t.array(t.string),
});

export const UserEventV = t.type({
  id: t.string,
  name: t.string,
  timestamp: t.string,
  widget: t.string,
  meta: t.record(t.string, t.any),
});

export const InteractionHistoryV = t.type({
  events: t.array(UserEventV),
});

export type IEndUserAdmin = t.TypeOf<typeof EndUserAdminV>;

export type IEndUserEvent = t.TypeOf<typeof UserEventV>;

export const EndUserTableDataV = t.type({
  users: t.array(EndUserAdminV),
  totalRows: t.number,
});

type EndUserListParams = PaginationFilter & { query?: string } & SortParams;

export type EndUserTableData = t.TypeOf<typeof EndUserTableDataV>;

export class EndUserAdmin {
  public static read = readObject(EndUserAdminV, 'end_users');
  // TODO: Remove this method once the new user property model has been fully rolled out
  public static properties = read(EndUserPropertiesV, `/end_users/properties`);
  public static customEvents = read(CustomEventsV, `/end_users/custom_events`);

  public static interactionHistory = async (slug: string) => {
    return read(InteractionHistoryV, `/end_users/${slug}/interaction_history`)();
  };

  public static list = async (params: EndUserListParams) => {
    return read(EndUserTableDataV, `/end_users/filtered_list`)(params);
  };

  public static getFilteredEndUsers = async (query: string) => {
    const response = await this.list({
      query,
      page: '0',
      page_size: '10',
    });

    return response.users;
  };
}
