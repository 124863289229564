export function extractFontFamily(fontFamily: string) {
  return fontFamily?.split(',')[0]?.replace(/['"]/g, '')?.trim();
}

export function isFontAvailable(fontFamily: string) {
  const testString = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const defaultFont = 'monospace'; // You can also use 'monospace' or 'serif'

  // Create a test element
  const iframe = document.getElementById('commandbar-editor-bar-preview') as HTMLIFrameElement | null;
  const iframeDocument = iframe?.contentDocument;
  if (!iframeDocument) {
    return true;
  }
  const testElement = iframeDocument.createElement('span');
  testElement.style.fontSize = '72px'; // Large font size to reduce precision errors
  testElement.style.visibility = 'hidden';
  testElement.style.position = 'absolute';
  testElement.style.top = '-9999px';
  testElement.innerHTML = testString;

  // Set the font to the default font and measure the width
  testElement.style.fontFamily = defaultFont;
  iframeDocument.body.appendChild(testElement);
  const defaultFontWidth = testElement.offsetWidth;

  // Set the font to the font we're testing and measure the width
  testElement.style.fontFamily = `${fontFamily}, ${defaultFont}`;
  const testFontWidth = testElement.offsetWidth;

  // Remove the test element from the DOM
  iframeDocument.body.removeChild(testElement);

  // If the widths are the same, the font is not available
  return defaultFontWidth !== testFontWidth;
}
