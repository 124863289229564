/*******************************************************************************/
/* Imports
/*******************************************************************************/

import { createObject, updateObject, deleteObject, decodeThrowing, createObjectWithDecoder } from './generics';
import { defaults, NamedRuleV } from './helpers/rules';
import * as t from 'io-ts';
import * as axiosInstance from './network';

import { decodeToPromise } from './generics';
import { IRuleExpression } from './types';

type User = {
  end_user: string;
  action: string | null | undefined;
};

export type AudienceFilter = 'eligible' | 'ineligible' | 'completed';

export type AudienceTableData = {
  total: number;
  users: User[];
};

export type AudienceUserParams = {
  expr: IRuleExpression | null;
  is_nudge: boolean;
  id: string | number;
  page: number;
  page_size: number;
  search_filter: string;
  filter: AudienceFilter;
};

const UserV = t.type({
  end_user: t.string,
  action: t.union([t.string, t.null, t.undefined]),
});

const ActionsTableV = t.type({
  total: t.number,
  users: t.array(UserV),
});

const AudienceUserV = t.type({
  users: ActionsTableV,
});

export class Rule {
  public static decode = (data: any) => decodeThrowing(NamedRuleV, { ...defaults, ...data });

  public static create = createObjectWithDecoder(Rule.decode, NamedRuleV, 'rules', { replace_existing_uses: 'false' });
  public static createFromExistingCondition = createObject(NamedRuleV, NamedRuleV, 'rules', {
    replace_existing_uses: 'true',
  });
  public static update = updateObject(NamedRuleV, NamedRuleV, 'rules');
  public static delete = deleteObject(NamedRuleV, 'rules');

  public static readEligible = async (params: AudienceUserParams) => {
    const result = await axiosInstance.post('rules/eligible_users/', params);
    return await decodeToPromise(t.exact(AudienceUserV), result.data);
  };
  public static readIneligible = async (params: AudienceUserParams) => {
    const result = await axiosInstance.post('rules/ineligible_users/', params);
    return await decodeToPromise(t.exact(AudienceUserV), result.data);
  };
  public static readUserActions = async (params: AudienceUserParams) => {
    const result = await axiosInstance.post('rules/completed_users/', params);
    return await decodeToPromise(t.exact(AudienceUserV), result.data);
  };
}
