import React, { useCallback, useEffect, useRef, useState } from 'react';
import { INamedRuleReference, IRuleExpression } from '@commandbar/internal/middleware/types';
import * as fetchClient from '@commandbar/internal/middleware/network';
import { throttle } from 'lodash';
import { CmdCard, CmdTooltip, CmdTypography } from '@commandbar/design-system/cmd';
import { AlertTriangle, InfoCircle } from '@commandbar/design-system/icons/react';
import CmdSpinner from '@commandbar/design-system/cmd/spinner/spinner';
import { Bar } from '@visx/shape';
import { scaleLinear } from '@visx/scale';

type ProgressBarProps = {
  progress: number;
};

const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setWidth(containerRef.current.offsetWidth);
      }
    };

    updateWidth();
    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  const xScale = scaleLinear({
    domain: [0, 100],
    range: [0, width],
  });

  const height = 12;
  const gap = 4;

  const activeSegment = progress <= 10 ? 0 : progress <= 30 ? 1 : 2;

  return (
    <div ref={containerRef} style={{ width: '100%' }}>
      <svg width={width} height={height}>
        <Bar
          x={0}
          y={0}
          width={Math.max(xScale(10) - gap / 2, 0)}
          height={height}
          fill={activeSegment === 0 ? '#2754EE' : '#F0F0F0'}
          rx={2}
          ry={2}
        />
        <Bar
          x={xScale(10) + gap / 2}
          y={0}
          width={Math.max(xScale(20) - gap / 2, 0)}
          height={height}
          fill={activeSegment === 1 ? '#4A70F2' : '#F0F0F0'}
          rx={2}
          ry={2}
        />
        <Bar
          x={xScale(30) + gap}
          y={0}
          width={Math.max(xScale(70) - gap, 0)}
          height={height}
          fill={activeSegment === 2 ? '#6E8DF5' : '#F0F0F0'}
          rx={2}
          ry={2}
        />
      </svg>
    </div>
  );
};

type AudienceSizeEstimationProps = {
  isInvalidRule?: boolean;
  expr: { type: 'all_users' } | IRuleExpression | INamedRuleReference;
};

export const AudienceSize: React.FC<AudienceSizeEstimationProps> = ({ expr, isInvalidRule }) => {
  const [loading, setLoading] = React.useState(isInvalidRule);
  const [error, setError] = React.useState<'InvalidOrEmptyConditionGroup' | 'NotImplementedError' | string | null>(
    null,
  );
  const [audienceSize, setAudienceSize] = React.useState<number | null>(null);
  const [totalCount, setTotalCount] = React.useState<number | null>(null);

  const calculateAudienceSize = useCallback(
    throttle(
      async (expr: AudienceSizeEstimationProps['expr']) => {
        setLoading(true);
        setError(null);
        try {
          const { data } = await fetchClient.post(`/rules/size/`, expr);

          setAudienceSize(data.size);
          setTotalCount(data.total);
        } catch (error) {
          setAudienceSize(null);
          setTotalCount(null);

          if ('code' in (error as { code: string })) {
            setError((error as { code: string }).code);
            return;
          }

          setError('Something went wrong');
        } finally {
          setLoading(false);
        }
      },
      5000,
      { leading: true, trailing: true },
    ),
    [],
  );

  useEffect(() => {
    setLoading(true);

    if (!isInvalidRule) {
      calculateAudienceSize(expr);
    }
  }, [expr, calculateAudienceSize, isInvalidRule]);

  if (loading) {
    return (
      <CmdCard>
        <CmdCard.Content>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CmdSpinner />
            <CmdTypography.Body style={{ marginLeft: '8px' }} variant="secondary">
              Calculating audience size...
            </CmdTypography.Body>
          </div>
        </CmdCard.Content>
      </CmdCard>
    );
  }

  if (error === 'NotImplementedError') {
    return (
      <CmdCard>
        <CmdCard.Content>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <AlertTriangle style={{ marginRight: '8px' }} />
            <CmdTypography.Body variant="secondary">The size of this audience cannot be estimated</CmdTypography.Body>
          </div>
        </CmdCard.Content>
      </CmdCard>
    );
  } else if (error) {
    return (
      <CmdCard>
        <CmdCard.Content>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <AlertTriangle style={{ marginRight: '8px' }} />
            <CmdTypography.Body variant="secondary">Something went wrong</CmdTypography.Body>
          </div>
        </CmdCard.Content>
      </CmdCard>
    );
  } else if (audienceSize === null || totalCount === null) {
    setError('Something went wrong');
    setAudienceSize(null);
    setTotalCount(null);

    return null;
  } else {
    const audiencePercent = (audienceSize / totalCount) * 100;
    const audienceSizeHeuristic = audiencePercent <= 10 ? 'Narrow' : audiencePercent <= 30 ? 'Targeted' : 'Broad';
    const tooltipMessage =
      audiencePercent <= 10
        ? 'Target less than 10% of total users. '
        : audiencePercent <= 30
        ? 'Target 11% to 30% of total users.'
        : 'Target 30%+ of total users.';

    return (
      <CmdCard style={{ padding: '16px' }}>
        <CmdCard.Content>
          <ProgressBar progress={audiencePercent} />
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <CmdTypography.Body style={{ display: 'inline' }} variant="contentMid">
                Audience is <span style={{ fontWeight: 600 }}>{audienceSizeHeuristic}</span>
              </CmdTypography.Body>
              <CmdTooltip message={tooltipMessage}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '4px' }}>
                  <InfoCircle style={{ color: '#797C85' }} />
                </div>
              </CmdTooltip>
            </span>

            <span>
              <CmdTypography.Body style={{ display: 'inline' }} variant="contentMid">
                {audienceSize}
              </CmdTypography.Body>{' '}
              <CmdTypography.Body style={{ display: 'inline' }} variant="secondary">
                est. users
              </CmdTypography.Body>
            </span>
          </div>
        </CmdCard.Content>
      </CmdCard>
    );
  }
};

export default AudienceSize;
