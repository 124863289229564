import React from 'react';

import './App.css';

import * as Sentry from '@sentry/react';
import * as axiosInstance from '@commandbar/internal/middleware/network';

import InAppRouter from './InAppRouter';

import { AuthProvider } from '@commandbar/internal/hooks/useAuth';
import { isInsideIFrame } from '@commandbar/internal/util/iframe';
import { IsEditorOpenProvider } from './hooks/useIsEditorOpen';
import { IUserType } from '@commandbar/internal/middleware/types';

import initSegment from './hooks/segment';
import { H } from 'highlight.run';
import { useIdentifyUser } from './hooks/useEventReporting';
import Auth from '@commandbar/internal/client/authentication';
import Sender from './management/Sender';
import { Alert } from '@commandbar/design-system/components/antd';

const InAppApp = () => {
  const inIFrame = React.useMemo(isInsideIFrame, []);

  const { identifyUser } = useIdentifyUser();

  const onUserChange = (user: IUserType | null) => {
    if (user !== null) {
      if (!user?.email.includes('@commandbar.com')) {
        initSegment();
        H.init('xdn5lld0');

        identifyUser(user, {
          segment: true,
          highlight: true,
        });
        H.getSessionURL().then((sessionUrl) => {
          Sentry.setContext('highlight', {
            url: sessionUrl,
          });
        });
      }
    }

    if (inIFrame) {
      if (user !== null) {
        Auth.token().then((token) => {
          Sender.synToken(token);
        });
      }

      Sender.shareUserAuthStatus(user !== null);
    }
  };

  return (
    <Sentry.ErrorBoundary
      fallback={<p>Something went wrong. Please reach out to support@commandbar.com if this persists.</p>}
    >
      <AuthProvider isEditor onUserChange={onUserChange} onLogout={() => Sender.logout()}>
        <DevEnvBanner />
        <IsEditorOpenProvider>
          <InAppRouter />
        </IsEditorOpenProvider>
      </AuthProvider>
    </Sentry.ErrorBoundary>
  );
};

const DevEnvBanner = () => {
  if (axiosInstance.getBaseURL().includes('api-dev.commandbar.com')) {
    return (
      <Alert
        banner
        type="warning"
        message={"You're on the dev env. Data last refreshed from prod on May 1."}
        style={{ padding: '15px 50px' }}
      />
    );
  } else {
    return null;
  }
};

export default InAppApp;
