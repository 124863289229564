import React from 'react';
import { CB_COLORS } from '@commandbar/design-system/components/antd';

import { FormLayout2 } from './FormLayout';

const VerifyEmail = () => {
  return (
    <FormLayout2 h1="Please Verify Your Email" h2="" error={''}>
      <br />
      <div style={{ textAlign: 'center' }}>
        <a
          href="https://commandbar.com/verify"
          style={{ color: CB_COLORS.primary, cursor: 'pointer', fontWeight: 650 }}
          target="_blank"
          rel="noreferrer"
        >
          Verify at commandbar.com
        </a>
      </div>
    </FormLayout2>
  );
};

export default VerifyEmail;
