import React from 'react';
import { CmdInput } from '@commandbar/design-system/cmd';
import { SearchSm } from '@commandbar/design-system/icons/react';

const CmdSearchInput = (props: React.ComponentProps<typeof CmdInput>) => (
  <div className={props.fullWidth ? 'w-full' : 'max-w-[250px]'}>
    <CmdInput prefixElement={<SearchSm />} clearable placeholder={'Search...'} {...props} fullWidth />
  </div>
);

export { CmdSearchInput };
