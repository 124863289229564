import React, { useState } from 'react';
import { DetailLink } from '../components/styled';
import { Targeting } from '../components/Targeting';
import { ArrowUpRight } from '@commandbar/design-system/icons/react';
import { useAppContext } from 'editor/src/AppStateContext';
import { INudgeStepType, INudgeType } from '@commandbar/internal/middleware/types';
import { usePreview } from 'editor/src/hooks/usePreview';
import { NudgeDetailContent } from './NudgeDetailContent';
import styled from '@emotion/styled';
import { CB_COLORS } from '@commandbar/design-system/colors';
import { getNudgeRoute } from '@commandbar/internal/proxy-editor/editor_routes';

// TODO: Remove All of the styled components and activeTab functionality when CmdTab is ready to be used
const TabButton = styled.div<{ isActive?: boolean }>`
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  padding: 8px 16px;
  border-bottom: 1px solid transparent;
  border-radius: 0px;
  color: ${CB_COLORS.neutral600};

  ${({ isActive }) =>
    isActive &&
    `
      border-bottom: 1px solid ${CB_COLORS.neutral1000};
      color: ${CB_COLORS.neutral1000};
    `}
`;

const TabList = styled.div`
  display: flex;
  position: relative;
  flex: none;
  align-items: center;
  padding: 8px 16px 0px 16px;
`;

const TabPane = styled.div`
  padding: 16px;
  flex: none;
  width: 100%;
  outline: none;
  overflow: hidden auto;
  height: 100%;
  background-color: ${CB_COLORS.neutral0};
  position: absolute;
`;

const TabPaneInner = styled.div`
  width: 100%;

  height: fit-content;
  margin-bottom: 16px;
`;

// content holder
const TabContent = styled.div`
  background-color: transparent;
  flex: auto;
  min-width: 0;
  min-height: 0;
`;

const TabContentInner = styled.div`
  position: relative;
  height: 100%;
  display: flex;
`;

enum NudgeFormTabs {
  DETAILS = 'tab-details',
  TARGETING = 'tab-targeting',
  ANALYTICS = 'tab-analytics',
}

type NudgeDetailTabsProps = {
  dirty: INudgeType;
  hasAnalytics: boolean;
  activeNudgeFormIndex?: number;
  isDirty: boolean;
  initialNudgeId: INudgeType['id'];
  onActiveNudgeFormChange: (index?: number) => void;
  onNudgeChange: (nudge: Partial<INudgeType>) => void;
  onStepChange: (index: number) => (step: INudgeStepType) => void;
};

export const NudgeDetailTabs = ({
  dirty,
  isDirty,
  initialNudgeId,
  onNudgeChange,
  activeNudgeFormIndex,
  onActiveNudgeFormChange,
  onStepChange,
}: NudgeDetailTabsProps) => {
  const { flags } = useAppContext();
  const { onStart } = usePreview();
  const [activeTab, setActiveTab] = useState(NudgeFormTabs.DETAILS);

  const onStartClickRecorder = (field: string, skipPrompt?: boolean) => {
    onStart({ type: 'element', experience: { type: 'nudge', nudge: dirty, field }, skipPrompt });
  };

  const DetailTab = {
    key: NudgeFormTabs.DETAILS,
    label: 'Details',
    children: (
      <NudgeDetailContent
        dirty={dirty}
        isDirty={isDirty}
        activeNudgeFormIndex={activeNudgeFormIndex}
        onActiveNudgeFormChange={onActiveNudgeFormChange}
        onNudgeChange={onNudgeChange}
        onStepChange={onStepChange}
      />
    ),
  };

  const TargetingTab = {
    key: NudgeFormTabs.TARGETING,
    label: 'Targeting',
    children: (
      <Targeting
        dirty={dirty}
        handleOnChange={onNudgeChange}
        onStartClickRecorder={flags['release-preview-v-2'] ? onStartClickRecorder : undefined}
      />
    ),
  };

  const AnalyticsTab = {
    key: NudgeFormTabs.ANALYTICS,
    label: (
      <DetailLink
        href={`${process.env.REACT_APP_DASHBOARD_URL}/analytics${getNudgeRoute(dirty)}/${initialNudgeId}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Analytics&nbsp;&nbsp;
        <ArrowUpRight width={14} height={14} />
      </DetailLink>
    ),
  };

  const tabs: { key: NudgeFormTabs; label: React.ReactNode; children?: JSX.Element }[] = [DetailTab, TargetingTab];
  if (Number(dirty.id) > 0) {
    tabs.push(AnalyticsTab);
  }

  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <TabList>
        {tabs.map((tab) => {
          return typeof tab.label === 'string' ? (
            <TabButton onClick={() => setActiveTab(tab.key)} isActive={tab.key === activeTab}>
              {tab.label}
            </TabButton>
          ) : (
            <div style={{ padding: '8px 16px' }}>{tab.label}</div>
          );
        })}
      </TabList>
      <TabContent>
        <TabContentInner>
          <TabPane>
            <TabPaneInner>{tabs.find((tab) => tab.key === activeTab)?.children}</TabPaneInner>
          </TabPane>
        </TabContentInner>
      </TabContent>
    </div>
  );
};
