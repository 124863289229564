import { getProxySDK } from '../client/proxy';
import { _sentry } from '../client/symbols';
import { post } from './network';

/**
 * XXX: This is a temporary hack so that we can remove the `Hub` type from the build output of the commandbar package.
 * Because this file is imported by middleware/types.tsx, which is imported everywhere, we need to make sure it doesn't contain the `Hub` type.
 * Once we break up the types.tsx file into smaller files, we can remove this hack.
 */
const getSentryForFlags = () => {
  return getProxySDK()[_sentry] as {
    captureException: (error: unknown) => void;
  };
};

/**
 * To use a Flag in the frontend, add it to the Flags type and the `defaultFlags` object below.
 */

export type Flags = {
  'release-search-experiences-in-help-hub': boolean;
  'release-search-experiences-in-spotlight': boolean;
  'support-ruby-on-rails': boolean;
  'release-disable-history-events': boolean;
  'release-themes-v2': boolean;
  'release-authed-zendesk-image-loading': boolean;
  'enable-pendo-open-guide-action': boolean;
  'show-incident-banner': '' | string;
  'show-dev-env-banner': boolean;
  'release-audiences-for-command-types': boolean;
  'copilot-actions-chain': boolean;
  'copilot-v-3': boolean;
  'release-analytics-events-refresh': boolean;
  'hackweek-live-events-table': boolean;
  'release-analytics-v2-qa': boolean;
  'release-preview-v-2': boolean;
  'segment-webhook-destination': boolean;
  'amplitude-audience-integration': boolean;
  'release-copilot-attachments': boolean;
  'release-copilot-impersonate-users': boolean;
  'release-copilot-action-audience': boolean;
  'enable-when-element-appears-trigger': boolean;
  'release-copilot-avatar-cdn': boolean;
  'release-chat-history': boolean;
  'release-zoho-work-drive-integration': boolean;
  'switch-open-help-doc-in-new-tab': boolean;
  'enable-inline-tooltip-options': boolean;
  'release-nudge-goals': boolean;
  'release-capture-custom-events': boolean;
  'release-widget-animations': boolean;
  'release-copilot-fallbacks-v-2': boolean;
  'release-user-property-management': boolean;
  'release-audience-refinement': boolean;
};

export const defaultFlags: Flags = {
  'release-search-experiences-in-help-hub': false,
  'release-search-experiences-in-spotlight': false,
  'support-ruby-on-rails': false,
  'release-disable-history-events': false,
  'release-themes-v2': false,
  'release-authed-zendesk-image-loading': false,
  'enable-pendo-open-guide-action': false,
  'show-incident-banner': '',
  'show-dev-env-banner': false,
  'release-audiences-for-command-types': false,
  'copilot-actions-chain': false,
  'copilot-v-3': false,
  'release-analytics-events-refresh': false,
  'hackweek-live-events-table': false,
  'release-analytics-v2-qa': false,
  'release-preview-v-2': false,
  'segment-webhook-destination': false,
  'amplitude-audience-integration': false,
  'release-copilot-attachments': false,
  'release-copilot-impersonate-users': false,
  'release-copilot-action-audience': false,
  'enable-when-element-appears-trigger': false,
  'release-copilot-avatar-cdn': false,
  'release-chat-history': false,
  'release-zoho-work-drive-integration': false,
  'switch-open-help-doc-in-new-tab': false,
  'enable-inline-tooltip-options': false,
  'release-nudge-goals': false,
  'release-capture-custom-events': false,
  'release-widget-animations': false,
  'release-copilot-fallbacks-v-2': false,
  'release-user-property-management': false,
  'release-audience-refinement': false,
};

const _fetchFlags = async (request: {
  batch: { key: string; default: any }[];
  organization_id: string;
}): Promise<{ flags: { [key: string]: any } }> => {
  const sentry = getSentryForFlags();

  try {
    const response = await post('/flags/', request, {
      credentials: 'include',
    });
    if (response.status !== 200) {
      throw new Error(`Failed to fetch flags: ${response.statusText}`);
    }
    if (response.data.error) {
      throw new Error(response.data.error);
    }
    return response.data as { flags: { [key: string]: any } };
  } catch (error) {
    sentry?.captureException(error);

    // return default values for all flags
    return { flags: Object.fromEntries(request.batch.map((b) => [b.key, b.default])) };
  }
};

export const fetchFlags = async (orgID: string): Promise<Flags> => {
  const batch = Object.entries(defaultFlags).map(([key, defaultValue]) => ({ key, default: defaultValue }));
  const { flags } = await _fetchFlags({ organization_id: orgID, batch });

  // Override flags with values from local storage if present
  for (const flag in flags) {
    const localStorageValue = localStorage.getItem(`commandbar.flags:${flag}`);
    if (localStorageValue !== null) {
      flags[flag] = JSON.parse(localStorageValue);
    }
  }

  return { ...defaultFlags, ...flags };
};
